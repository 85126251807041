export const GithubIcon = (
	<svg
		version='1.0'
		xmlns='http://www.w3.org/2000/svg'
		width='25px'
		height='25px'
		viewBox='0 0 512.000000 512.000000'
		preserveAspectRatio='xMidYMid meet'
	>
		<g transform='translate(0.000000,512.000000) scale(0.100000,-0.100000)'>
			<path
				d='M4030 5111 c-132 -24 -380 -132 -577 -251 l-103 -62 -71 17 c-153 34
-278 46 -546 52 -356 8 -613 -17 -805 -77 l-60 -18 -90 59 c-255 167 -535 278
-704 279 l-52 0 -20 -48 c-82 -191 -104 -479 -52 -706 l20 -88 -81 -107 c-93
-123 -169 -272 -199 -388 -66 -265 -43 -649 54 -908 75 -198 176 -355 326
-506 258 -259 551 -393 1013 -464 l38 -6 -64 -64 c-71 -72 -123 -159 -147
-250 -15 -56 -19 -61 -64 -83 -135 -65 -398 -69 -533 -7 -66 30 -125 85 -221
204 -211 265 -289 322 -441 322 -83 0 -119 -8 -131 -31 -16 -30 -3 -46 106
-130 57 -44 126 -109 153 -143 27 -34 86 -136 131 -226 74 -148 89 -171 159
-241 83 -85 172 -135 291 -167 71 -19 317 -25 443 -10 l67 8 0 -306 c0 -284
-1 -308 -19 -343 -28 -52 -55 -81 -138 -146 -86 -67 -102 -91 -79 -115 28 -27
214 -22 289 8 81 32 175 98 211 149 l31 44 8 441 c4 243 12 465 18 494 10 50
62 143 80 143 12 0 11 -859 -1 -1034 -12 -160 -32 -218 -100 -292 -47 -51 -50
-77 -14 -101 23 -15 33 -15 87 -4 85 18 146 51 221 122 137 128 149 197 150
864 1 301 5 473 11 477 6 4 25 8 43 8 l33 0 -1 -522 c-1 -588 3 -639 64 -741
31 -53 75 -95 148 -141 121 -76 211 -94 234 -47 17 33 5 61 -59 135 -121 139
-117 117 -117 748 0 556 -1 547 49 494 60 -63 83 -203 111 -661 21 -339 21
-340 51 -403 47 -98 134 -160 264 -188 118 -26 255 -10 255 30 0 8 -44 50 -97
93 -75 60 -104 91 -126 133 l-29 55 5 335 c6 365 -3 601 -28 725 -28 140 -94
254 -184 321 -28 21 -48 41 -46 44 2 4 32 10 65 13 33 4 107 16 163 27 623
126 1016 457 1132 956 51 218 63 576 27 774 -22 119 -50 211 -97 315 -35 79
-73 139 -164 258 l-74 99 14 111 c18 149 16 372 -4 463 -16 70 -49 166 -66
193 -11 17 -97 23 -161 12z'
			/>
		</g>
	</svg>
);

export const LinkedInIcon = (
	<svg version='1.1' width='25px' height='25px' viewBox='0 0 512 512'>
		<g>
			<rect height='328.97' width='106.716' x='17.397' y='166.28' />
			<path d='M414.789,170.138c-1.133-0.355-2.207-0.743-3.396-1.078c-1.438-0.327-2.865-0.6-4.328-0.833 c-5.662-1.139-11.875-1.946-19.148-1.946c-62.211,0-101.678,45.372-114.674,62.894V166.28H166.526v328.97h106.716V315.813 c0,0,80.643-112.628,114.674-29.911V495.25h106.688V273.257C494.604,223.549,460.629,182.13,414.789,170.138L414.789,170.138z M414.789,170.138' />
			<path d='M121.789,69.085c0,28.909-23.373,52.337-52.195,52.337c-28.824,0-52.196-23.429-52.196-52.337 c0-28.903,23.372-52.335,52.196-52.335C98.416,16.75,121.789,40.182,121.789,69.085L121.789,69.085z M121.789,69.085' />
		</g>
	</svg>
);

export const TelegramIcon = (
	<svg
		version='1.0'
		xmlns='http://www.w3.org/2000/svg'
		width='25px'
		height='25px'
		viewBox='0 0 512.000000 449.000000'
		preserveAspectRatio='xMidYMid meet'
	>
		<g
			transform='translate(0.000000,449.000000) scale(0.100000,-0.100000)'
			stroke='none'
		>
			<path d='M4665 4416 c-27 -8 -423 -157 -880 -331 -1141 -436 -1919 -733 -2790 -1065 -407 -155 -769 -296 -804 -313 -69 -33 -140 -96 -166 -146 -25 -50 -30 -121 -11 -179 21 -62 97 -137 176 -174 30 -14 200 -67 378 -118 l322 -91 258 132 c514 263 709 363 1377 704 1233 630 1363 695 1387 695 39 0 111 -42 131 -77 24 -41 24 -115 2 -150 -10 -15 -378 -409 -818 -877 -440 -468 -811 -863 -824 -877 -26 -28 -79 -209 -64 -219 4 -3 320 -257 702 -565 382 -308 716 -574 743 -592 108 -74 271 -93 378 -45 90 39 176 138 213 242 9 25 178 844 377 1820 329 1618 361 1784 361 1875 1 80 -4 111 -21 156 -49 124 -159 200 -300 206 -46 2 -97 -3 -127 -11z' />
			<path d='M2201 2334 c-542 -278 -986 -506 -988 -507 -6 -7 470 -1353 490 -1387 27 -45 69 -101 73 -98 1 2 75 298 164 658 89 360 168 665 175 677 11 17 848 912 1048 1121 23 23 37 42 32 41 -6 -1 -453 -228 -994 -505z' />
			<path d='M2156 622 c-53 -213 -96 -391 -96 -396 0 -19 108 5 176 39 50 24 107 67 195 147 181 164 265 246 259 251 -31 26 -418 335 -426 340 -8 5 -41 -111 -108 -381z' />
		</g>
	</svg>
);

export const CvIcon = (
	<svg
		version='1.0'
		xmlns='http://www.w3.org/2000/svg'
		width='25px'
		height='25px'
		viewBox='0 0 406.000000 346.000000'
		preserveAspectRatio='xMidYMid meet'
	>
		<g
			transform='translate(0.000000,346.000000) scale(0.100000,-0.100000)'
			stroke='none'
		>
			<path d='M175 3436 c-64 -30 -106 -65 -144 -123 l-31 -46 0 -1532 0 -1532 31 -46 c36 -55 89 -99 156 -130 l48 -22 1785 0 1785 0 60 28 c76 36 147 110 175 183 20 54 20 68 20 1519 0 1451 0 1465 -20 1519 -27 71 -99 147 -170 180 l-55 26 -1795 0 -1795 0 -50 -24z m1180 -632 c127 -30 257 -113 346 -221 81 -99 149 -271 149 -380 l0 -43 -216 2 -217 3 -12 47 c-46 181 -282 223 -390 70 l-30 -44 0 -528 0 -528 30 -44 c37 -53 120 -98 179 -98 57 0 138 40 171 85 16 20 34 57 40 83 l12 47 217 3 216 2 0 -43 c0 -63 -33 -184 -71 -258 -85 -170 -239 -296 -420 -345 -86 -23 -242 -23 -329 0 -227 62 -411 250 -470 482 -19 72 -20 112 -20 614 0 502 1 542 20 614 28 111 83 209 167 296 168 177 388 241 628 184z m1221 -184 c28 -113 98 -395 155 -627 57 -233 106 -423 110 -423 6 0 86 314 259 1025 29 116 56 216 62 223 8 9 66 12 226 12 l215 0 -6 -42 c-4 -24 -62 -270 -131 -548 -201 -816 -254 -1030 -322 -1310 -35 -146 -68 -282 -74 -303 l-11 -37 -215 0 c-213 0 -215 0 -224 23 -6 12 -37 132 -69 267 -33 135 -130 531 -216 880 -241 981 -256 1043 -251 1057 5 11 49 13 223 11 l218 -3 51 -205z' />
		</g>
	</svg>
);

export const DonateIcon = (
	<svg viewBox='0 0 512 512' width='25px' height='25px'>
		<path d='M256 416c114.9 0 208-93.1 208-208S370.9 0 256 0 48 93.1 48 208s93.1 208 208 208zM233.8 97.4V80.6c0-9.2 7.4-16.6 16.6-16.6h11.1c9.2 0 16.6 7.4 16.6 16.6v17c15.5.8 30.5 6.1 43 15.4 5.6 4.1 6.2 12.3 1.2 17.1L306 145.6c-3.8 3.7-9.5 3.8-14 1-5.4-3.4-11.4-5.1-17.8-5.1h-38.9c-9 0-16.3 8.2-16.3 18.3 0 8.2 5 15.5 12.1 17.6l62.3 18.7c25.7 7.7 43.7 32.4 43.7 60.1 0 34-26.4 61.5-59.1 62.4v16.8c0 9.2-7.4 16.6-16.6 16.6h-11.1c-9.2 0-16.6-7.4-16.6-16.6v-17c-15.5-.8-30.5-6.1-43-15.4-5.6-4.1-6.2-12.3-1.2-17.1l16.3-15.5c3.8-3.7 9.5-3.8 14-1 5.4 3.4 11.4 5.1 17.8 5.1h38.9c9 0 16.3-8.2 16.3-18.3 0-8.2-5-15.5-12.1-17.6l-62.3-18.7c-25.7-7.7-43.7-32.4-43.7-60.1.1-34 26.4-61.5 59.1-62.4zM480 352h-32.5c-19.6 26-44.6 47.7-73 64h63.8c5.3 0 9.6 3.6 9.6 8v16c0 4.4-4.3 8-9.6 8H73.6c-5.3 0-9.6-3.6-9.6-8v-16c0-4.4 4.3-8 9.6-8h63.8c-28.4-16.3-53.3-38-73-64H32c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-96c0-17.7-14.3-32-32-32z' />
	</svg>
);
